export function isIE() {
    const bw = window.navigator.userAgent
    const compare = (s) => bw.indexOf(s) >= 0
    const ie11 = (() => 'ActiveXObject' in window)()
    return compare('MSIE') || ie11
}

/**
 * 格式化万位
 * @param {*} value // 值
 * @param {*} n //保留小数位
 * @returns 
 */
export function million(value, n) {
    let num;
    if (value > 9999) { //大于9999显示x.xx万
        num = (Math.floor(value / 100) / 100).toFixed(2) + '万';
        console.log(num)
    } else if (value <= 9999 && value > -9999) {
        n ? num = value.toFixed(n) : num = value
    } else if (value < -9999) { //小于-9999显示-x.xx万
        num = -(Math.floor(Math.abs(value) / 1000) / 10).toFixed(2) + '万'
    }
    return num;
}
