import request from '@/utils/request'

const api = {
  apiDataGamedetail: '/api/data/topreelsgamedetail', // 每日数据列表
  apiDataGamedetailoverview: '/api/data/topreelsgamedetailoverview', // 月数据汇总
  apiDataGamedetailchart: '/api/data/topreelsgamedetailchart'// 报表数据
}

export function apiDataGamedetail(parameter) {
  return request({
    url: api.apiDataGamedetail,
    method: 'get',
    params: parameter
  })
}

export function apiDataGamedetailoverview(parameter) {
  return request({
    url: api.apiDataGamedetailoverview,
    method: 'get',
    params: parameter
  })
}

export function apiDataGamedetailchart(parameter) {
  return request({
    url: api.apiDataGamedetailchart,
    method: 'get',
    params: parameter
  })
}
