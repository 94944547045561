<template>
  <div>
    <van-sticky>
      <div class="scroll-top">
        <div class="head" :style="{ position: headpos }">
          <Tabs :tabs="tabs" :fnClickTabs="fnClickTabs" :currentIndex="currentIndex" style="width: 3.5rem"></Tabs>
        </div>
        <!-- 更新时间 -->
        <div class="time">
          <span>{{ time }}</span>
        </div>
        <!-- 更新时间 -->
      </div>
    </van-sticky>
    <!-- 总收入明细 -->
    <div class="head-box">
      <div class="head-top">
        <div class="head-top-left">
          <h2>
            <i>{{ conf.symbol }}{{ info.pay | million | fillZero | priceInit }} </i>
            <span v-if="info.pay > 9999">万</span>
          </h2>
          <span class="s">总收入</span>
        </div>
      </div>
      <div class="head-list">
        <ul>
          <li class="t">
            <span>内购收入:</span>
            <template v-if="info.pay_amount !== null"
              >{{ conf.symbol }}{{ info.pay_amount | million | fillZero | priceInit
              }}<template v-if="info.pay_amount > 9999">万</template></template
            >
            <template v-else>-</template>
          </li>
          <li>
            <span>广告收入:</span>
            <template v-if="info.ad_amount !== null"
              >{{ conf.symbol }}{{ info.ad_amount | million | fillZero | priceInit
              }}<template v-if="info.ad_amount > 9999">万</template></template
            >
            <template v-else>-</template>
          </li>
          <li class="t"><span>新增人数:</span>{{ info.reg_num | priceInit }}</li>
          <li>
            <span>活跃人数:</span>
            <template v-if="info.login_num !== null">{{ info.login_num | priceInit }}</template>
            <template v-else>-</template>
          </li>
          <li class="t">
            <span>付费人数:</span>
            <template v-if="info.pay_user_num !== null">{{ info.pay_user_num | priceInit }}</template>
            <template v-else>-</template>
          </li>
          <li v-if="dataAccess">
            <span>消耗:</span>
            <template v-if="info.total_cost !== null"
              >{{ conf.symbol }}{{ info.total_cost | million | fillZero | priceInit
              }}<template v-if="info.total_cost > 9999">万</template></template
            >
            <template v-else>-</template>
          </li>
          <li class="t" v-if="isHideTips && hwDataAccess">
            <span>毛利:</span>
            <template v-if="info.gross_profit !== null"
              >{{ conf.symbol }}{{ info.gross_profit | million | fillZero | priceInit
              }}<template v-if="info.gross_profit > 9999">万</template></template
            >
            <template v-else>-</template>
          </li>
        </ul>
      </div>
    </div>
    <!-- /总收入明细 -->
    <!-- 数据报表切换 -->
    <div class="tabs">
      <div class="tabs-wh" @click.stop="fnClickTips()" v-if="isHideTips && hwDataAccess">
        <span></span>
      </div>
      <Tabs :tabs="dataTabs" :fnClickTabs="fnClickDataTabs" :fontSize="'0.32rem'" :lineWidth="'100%'"></Tabs>
    </div>
    <!-- /数据报表切换 -->
    <!-- 饼图展示 -->
    <div class="pielist">
      <div class="pielist-title">{{ dataTabsType === 1 ? '收入' : dataTabsType === 2 ? '消耗' : '毛利' }}占比</div>
      <div class="pielist-box">
        <div class="pielist-box-left">
          <div class="pielist-box-left-total">
            {{ conf.symbol + pietotal }}
          </div>
          <EchartPie :dataList="pieDataList" width="3.34rem" height="3.34rem"></EchartPie>
        </div>
        <div class="pielist-box-right">
          <ul>
            <li v-for="(item, index) in dataGameSummary" :key="index" @click="fnOpenDetails(item[0])">
              <span class="s1" :class="'c' + (index + 1)"></span>
              <span class="s2">{{ item[0] | gamename(0) }}</span>
              <span class="s3">{{ item[1] }}%</span>
              <span class="s4">
                {{ conf.symbol }}{{ item[2] | million | fillZero | priceInit
                }}<template v-if="item[2] > 9999">万</template></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /饼图展示 -->
    <!-- 数据报表 -->
    <div class="data-list">
      <div class="data-list-title">
        <span>{{ dataTabsType === 1 ? '收入' : dataTabsType === 2 ? '消耗' : '毛利' }}趋势</span>
      </div>
      <EchartColumn :dataList="dataList" id="maindata" height="4.3rem"></EchartColumn>
      <div class="data-list-quanping" @click="fnFullScree">
        <em></em>
      </div>
    </div>
    <!-- /数据报表 -->
    <!-- 数据概览 -->
    <div class="data-overview" ref="dataScroll">
      <div ref="bottomColumn">
        <van-sticky>
          <div class="data-overview-top">
            <h2><em></em>数据概览</h2>
          </div>
        </van-sticky>
      </div>
      <!-- 数据内容 -->
      <!-- 浮动表头 -->
      <div
        v-show="isHideShow"
        class="data-overview-bottom-column"
        :style="{
          position: 'fixed',
          width: tableWidth + 42 + columnOtherWidth.length * 30 + 'px',
          top: topHeight + 'px',
          left: topLeft + 'px',
          transform: 'translateZ(0)',
          '-webkit-transform': 'translateZ(0)'
        }"
      >
        <div class="data-overview-bottom-column-span">
          <span
            v-for="(item, index) in column"
            :key="index"
            :class="[index === 0 ? 'l' : '']"
            :style="{ width: columnOtherWidth.indexOf(index) !== -1 ? '120px' : '' }"
          >
            {{ columnlist[item] }}
          </span>
        </div>
      </div>
      <!-- /浮动表头 -->
      <div class="data-overview-bottom" @scroll="fnScroll">
        <!-- 浮动左侧 -->
        <div
          class="data-overview-left"
          :class="[boxLeft ? 'boxshadow' : '']"
          :style="{
            position: 'fixed',
            top: leftTopFix + 'px',
            transform: 'translateZ(0)',
            '-webkit-transform': 'translateZ(0)'
          }"
          v-show="isLeftShow"
        >
          <div class="data-overview-left-top">
            <span class="l">{{ columnlist[column[0]] }}</span>
          </div>
          <div class="data-overview-bottom-box" :style="{ position: 'relative', top: topTop + 'px' }">
            <div
              class="data-overview-bottom-box-list"
              v-for="(item, index) in tableData"
              :key="index"
              :style="{ 'font-weight': index == tableData.length - 1 ? 'bold' : 'normal' }"
              @click="fnOpenDetails(item[0])"
            >
              <span class="l">
                <template>{{ item[0] == 'total' ? '总计/平均' : item[0] | gamename(0) }}</template>
              </span>
            </div>
          </div>
        </div>
        <!-- /浮动左侧 -->
        <!-- 固定表头 -->
        <div
          class="data-overview-bottom-column g"
          ref="columnHeight"
          :style="{ width: tableWidth + 42 + columnOtherWidth.length * 30 + 'px' }"
        >
          <span
            v-for="(item, index) in column"
            :key="index"
            :class="[index === 0 ? 'l' : '']"
            :style="{ width: columnOtherWidth.indexOf(index) !== -1 ? '120px' : '' }"
          >
            {{ columnlist[item] }}
          </span>
        </div>
        <!-- /固定表头 -->
        <div
          class="data-overview-bottom-box"
          ref="bottomList"
          :style="{ width: tableWidth + 42 + columnOtherWidth.length * 30 + 'px' }"
        >
          <div
            class="data-overview-bottom-box-list"
            v-for="(item, index) in tableData"
            :key="index"
            :style="{ 'font-weight': index == tableData.length - 1 ? 'bold' : 'normal' }"
          >
            <span
              v-for="(itemlist, itemlistindex) in item"
              :key="itemlistindex"
              :class="[itemlistindex === 0 ? 'l' : '']"
              @click="fnOpenDetails(item[0])"
              :style="{ width: columnOtherWidth.indexOf(itemlistindex) !== -1 ? '120px' : '' }"
            >
              <template v-if="itemlist !== null || itemlistindex === 0">
                <!-- 处理加加钱符号数字 -->
                <template v-if="columnIndexMoney.indexOf(itemlistindex) !== -1">
                  {{ conf.symbol }}{{ itemlist | million | fillZero | priceInit
                  }}<template v-if="itemlist > 9999">万</template>
                </template>
                <!-- 处理加%号数字 -->
                <template v-else-if="columnIndex.indexOf(itemlistindex) !== -1">{{ itemlist | fillZero }}%</template>
                <!-- 特殊处理首列 -->
                <template v-else-if="itemlistindex === 0">
                  {{ itemlist == 'total' ? '总计/平均' : itemlist | gamename(0) }}
                </template>
                <!-- 处理不是金额类数字 -->
                <template v-else>{{ itemlist | priceInit }}</template>
              </template>
              <template v-else>-</template>
            </span>
          </div>
        </div>
      </div>
      <!-- /数据内容 -->
    </div>
    <!-- /数据概览 -->
    <!-- 全屏 -->
    <div class="chart-full-box" v-show="showChartFull">
      <div class="chart-full-box-list">
        <EchartColumn
          :dataList="dataList"
          :width="screenHeight + 'px'"
          :height="screenWidth + 'px'"
          :id="'mainFull'"
        ></EchartColumn>
      </div>
      <div class="chart-full-box-close" @click="fnFullScree">
        <span></span>
      </div>
    </div>
    <!-- /全屏图表 -->
    <van-overlay :show="isTipsShow" @click="isTipsShow = false" z-index="100">
      <div class="tips-box" @click.stop>
        <div class="tips">
          <p>
            （1）毛利=每个产品的毛利之和；<br />
            （2）每个产品的毛利=内购收入*（1-内购分成）+广告收入*（1-广告分成）-广告消耗；<br />
            （3）“内购分成”与“广告分成”读取发行后台的配置
          </p>
          <div class="tips-button" @click="isTipsShow = false">我知道了</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showLoading" z-index="100">
      <div class="vant-loading">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { dataTypeOverview, dataTypeChart, dataTypeDetail } from '@/api/alldetails'
import { Tabs, EchartPie, EchartColumn } from '@/components'
import { million } from '@/utils/util'
export default {
  components: {
    Tabs,
    EchartPie,
    EchartColumn
  },
  data() {
    return {
      isTipsShow: false,
      // 年月切换
      headpos: 'relative',
      currentIndex: 0,
      time_type: 1,
      time: null,
      tabs: [],
      game_type: 0,
      showLoading: false,
      screenWidth: null, // 屏幕宽
      screenHeight: null, // 屏幕高
      info: {
        ad_amount: 0,
        gross_profit: 0,
        login_num: 0,
        pay: 0,
        pay_amount: 0,
        pay_user_num: 0,
        reg_num: 0,
        total_cost: 0
      },
      gameId: null,
      currentDay: null,
      currentText: '本月',
      // 图表切换
      dimension: 'day',
      dataGameSummary: [],
      allDataIndex: [],
      allDataList: [],
      dataList: {},
      dataListFull: {},
      dataTabsType: 1,
      pietotal: 0,
      dataTabs: [],
      isHideTips: 0,
      dataAccess: 0,
      hwDataAccess: 0,
      showChartFull: false,
      pieDataList: {},
      // 数据概览
      tabsOverview: [
        {
          name: '近7天',
          value: 1
        },
        {
          name: '近30天',
          value: 2
        },
        {
          name: '自定义',
          value: 3
        }
      ],
      topHeight: 0, // 固定高度
      topLeft: 0,
      topTop: 0,
      boxLeft: 0,
      leftTopFix: 0,
      isHideShow: false,
      isLeftShow: false,
      // 定义表头
      columnlist: {
        //产品, 新增人数、活跃人数、付费人数、内购收入、广告收入、总收入、毛利、毛利率、付费率、ARPU、ARPPU
        game_name: '产品',
        reg_num: '新增人数',
        login_num: '活跃人数',
        pay_user_num: '付费人数',
        pay_amount: '内购收入',
        ad_amount: '广告收入',
        total_amount: '总收入',
        total_cost: '广告消耗',
        gross_profit: '毛利',
        gross_profit_ratio: '毛利率',
        pay_ratio: '付费率',
        total_arpu: '总ARPU',
        total_arppu: '总ARPPU',
        ARPU: 'ARPU',
        ARPPU: 'ARPPU'
      },
      column: [
        'game_name',
        'reg_num',
        'login_num',
        'pay_user_num',
        'pay_amount',
        'ad_amount',
        'total_amount',
        'total_cost',
        'gross_profit',
        'gross_profit_ratio',
        'pay_ratio',
        'total_arpu',
        'total_arppu'
      ],
      columnIndex: [], //需要显示%符号的列有毛利和消耗列
      columnIndexMoney: [], //需要显示钱符号的列有毛利和消耗列
      columnOtherWidth: [], //特殊处理宽度列
      tableData: [],
      tableWidth: null,
      columnWidth: 90,
      // 钱的符号
      conf: {
        type: '',
        symbol: ''
      }
    }
  },
  mounted() {
    // 获取当前切换年月日标签状态
    if (sessionStorage.getItem('currentIndex')) {
      this.currentIndex = parseInt(sessionStorage.getItem('currentIndex'))
      this.time_type = parseInt(sessionStorage.getItem('currentTimeType'))
    }
    // 数据类型
    this.game_type = this.$route.query.game_type
    console.log(this.game_type, this.time_type)
    // 初始化加载数据
    this.$nextTick(() => {
      this.topHeight = this.$refs.bottomColumn.getBoundingClientRect().height // 浮动顶部的高度
      this.columnHeight = this.$refs.columnHeight.getBoundingClientRect().height // 表头的高度
      this.screenWidth = document.body.clientWidth
      this.screenHeight = document.body.clientHeight
      window.removeEventListener('scroll', this.handScroll, true)
      window.addEventListener('scroll', this.handScroll, true)
      this.fnInit()
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handScroll, true)
  },
  methods: {
    fnInit() {
      this.fnDataTypeOverview()
      this.fnDataTypeChart()
      this.fnDataTypeDetail()
    },
    /**
     * 游戏大类详情
     */
    fnDataTypeOverview() {
      const params = {
        game_type: this.game_type, //(number,require) - 游戏id
        time_type: this.time_type //(number,normal) - 1:本月2:上月3:今年4:去年
      }
      dataTypeOverview(params).then((data) => {
        console.log(data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          const daylist = [
            {
              name: '本月',
              value: 1
            },
            {
              name: '上月',
              value: 2
            },
            {
              name: '今年',
              value: 3
            },
            {
              name: '去年',
              value: 4
            }
          ]
          // 判断是否本月第1天
          if (parseInt(res.current_day) === 1) {
            daylist.splice(0, 1)
          }
          this.tabs = daylist
          // 没有权限不显示消耗和毛利
          this.isHideTips = res.data_access
          this.dataAccess = res.data_access
          this.hwDataAccess = res.hw_data_access
          this.time = res.start_date + '-' + res.end_date
          this.info = res.info
          this.conf = res.conf
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 游戏大类概览
     */
    fnDataTypeDetail() {
      const params = {
        game_type: this.game_type, //(number,require) - 游戏id
        time_type: this.time_type //(number,normal) - 1:本月2:上月3:今年4:去年
      }
      dataTypeDetail(params).then((data) => {
        console.log(data)
        const error = data.error
        if (error === 1000) {
          const res = data.res
          this.column = res.index
          // 获取表头后，计算表格宽度
          this.tableWidth = this.column.length * this.columnWidth
          //需要补零操作
          const dataArray = res.data
          dataArray.push(res.data_summary)
          this.tableData = dataArray
          // 没有权限不显示消耗和毛利
          this.isHideTips = res.data_access
          this.dataAccess = res.data_access
          this.hwDataAccess = res.hw_data_access
          this.conf = res.conf
          if (!this.isHideTips) {
            this.columnIndex = [7] //需要显示%符号的列没有毛利和消耗列
            this.columnIndexMoney = [4, 5, 6, 8, 9] //需要显示钱符号的列没有毛利和消耗列
            this.columnOtherWidth = [4, 5, 6] //需要加宽的列
          } else {
            //判断是否有毛利白名单
            if (this.hwDataAccess) {
              this.columnIndex = [9] //需要显示%符号的列有毛利和消耗列
              this.columnIndexMoney = [4, 5, 6, 7, 8, 10, 11] //需要显示钱符号的列有毛利和消耗列
              this.columnOtherWidth = [4, 5, 6, 7, 8] //需要加宽的列
            } else {
              this.columnIndex = [8] //需要显示%符号的列没有毛利和有消耗列
              this.columnIndexMoney = [4, 5, 6, 7, 9, 10] //需要显示钱符号的列没有毛利和消耗列
              this.columnOtherWidth = [4, 5, 6, 7] //需要加宽的列
            }
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    //计算最小值
    calMin(arr) {
      let min = 0
      arr.forEach((el) => {
        el.forEach((el1) => {
          if (!(el1 === undefined || el1 === '')) {
            if (min > el1) {
              min = el1
            }
          }
        })
      })
      return Math.ceil(min)
    },
    /**
     * 获取图表详情
     */
    fnDataTypeChart() {
      const params = {
        game_type: this.game_type, //游戏ID
        time_type: this.time_type, // 1:本月2:上月3:今年4:去年
        data_type: this.dataTabsType //(number,normal) - 1:收入 2:广告 3:毛利
      }
      this.showLoading = true
      dataTypeChart(params).then((data) => {
        console.log('图表详情', data)
        this.showLoading = false
        const error = data.error
        if (error === 1000) {
          const res = data.res
          let totalnum = 0
          const preData = []
          //饼图
          this.dataGameSummary = res.data_game_summary
          for (let index = 0; index < this.dataGameSummary.length; index++) {
            const element = this.dataGameSummary[index]
            totalnum += element[2] * 100
            preData.push({
              value: element[2],
              name: element[0].split('|')[0]
            })
          }
          console.log('preData', preData)
          this.pietotal = million(totalnum / 100)
          const pieSeriesArray = [
            {
              name: this.dataTabsType,
              type: 'pie',
              radius: ['70%', '100%'],
              avoidLabelOverlap: false,
              silent: true,
              label: {
                show: false
              },
              emphasis: {
                label: {
                  show: false
                }
              },
              data: preData
            }
          ]
          this.pieDataList = {
            seriesArray: pieSeriesArray
          }
          // 柱状图
          const tabsArray = [
            {
              name: '收入',
              value: 1
            },
            {
              name: '消耗',
              value: 2
            },
            {
              name: '毛利',
              value: 3
            }
          ]
          // 没有权限不显示消耗和毛利
          if (!res.data_access) {
            tabsArray.splice(1, 2)
          }
          // 隐藏毛利不在白名单内
          if (!res.hw_data_access) {
            tabsArray.splice(2, 1)
          }
          this.isHideTips = res.data_access
          this.dataAccess = res.data_access
          this.hwDataAccess = res.hw_data_access
          this.conf = res.conf
          this.dataTabs = tabsArray
          this.allDataList = [].concat(res.data)
          this.allDataIndex = [].concat(res.index)
          let yAxisArray = [] // y轴
          let seriesArray = [] // 数据显示
          let echartsType = [] // 纵坐标样式
          let echartsY = [] // 纵坐标显示以哪个维度
          // 图表控制上下左右边距
          const gridArray = {
            left: '5%',
            right: '5%',
            top: '8%',
            bottom: '20%',
            containLabel: true
          }
          const dataZoomArray = [
            {
              type: 'inside',
              xAxisIndex: [0],
              filterMode: 'filter',
              startValue: res.display_time_start
                ? res.display_time_start
                : this.allDataList.length > 14
                ? this.allDataList.length - 14
                : 0,
              endValue: res.display_time_end ? res.display_time_end : this.allDataList.length - 1
              //startValue: this.allDataList.length - 14 > 0 ? this.allDataList.length - 14 : 0,
              //endValue: this.allDataList.length - 1
            }
          ]
          // x轴
          let xAxisArray = {
            type: 'category',
            data: []
          }
          // 标签
          let legendArray = {
            bottom: 10,
            itemWidth: 6,
            itemHeight: 6,
            height: 30,
            fonSize: 14,
            icon: 'circle',
            itemGap: 10,
            zlevel: 10,
            formatter: function (name) {
              return name.length > 4 ? name.substr(0, 4) + '...' : name
            },
            data: []
          }
          // 提示
          let tooltipArray = {
            trigger: 'axis',
            confine: true,
            position: function (pt) {
              return [pt[0], 130]
            }
          }
          // 画的坐标轴
          echartsY = [0, 1] // 画的坐标轴

          // 1:新增 2:活跃 3:收入 4:广告
          const _this = this
          echartsType = ['bar', 'bar', 'bar', 'bar', 'bar'] // 线的类型
          echartsY = [0, 0, 0, 0] // 画的坐标轴
          // 组装图表X坐标轴
          for (let index = 0; index < echartsType.length; index++) {
            const e = this.allDataIndex[index + 1]
            // 设置标签中文名
            legendArray.data.push(e)
            // 设置坐标轴serise的属性
            seriesArray.push({
              name: e, // 名称
              type: echartsType[index], // 图表的类型
              yAxisIndex: echartsY[index], //设置根据哪一个标尺
              stack: '总览',
              data: []
            })
          }
          // 组装给图表Y轴的数据
          for (let index = 0; index < this.allDataList.length; index++) {
            const e = this.allDataList[index]
            for (let t = 0; t <= echartsType.length; t++) {
              const m = e[t]
              if (!t) {
                xAxisArray.data.push(m)
              } else {
                seriesArray[t - 1].data.push(m)
              }
            }
          }
          yAxisArray = [
            {
              type: 'value',
              position: 'left',
              min: 0,
              scale: true,
              textStyle: {
                color: '#7a7a7a'
              }
            }
          ]
          yAxisArray[0].axisLabel = {
            formatter(e) {
              return million(e)
            }
          }
          // 鼠标触发效果
          tooltipArray = {
            trigger: 'axis',
            formatter: function (params) {
              let allNum = 0
              let a = '<div>'
              a += '<p>' + params[0].name + '</p>'
              // 计算总收入
              for (let index = 0; index < params.length; index++) {
                const element = params[index]
                if (element.value) {
                  allNum += element.value
                }
              }
              if (allNum > 0) {
                //dataTabsType === 1 ? '收入' : dataTabsType === 2 ? '消耗' : '毛利'
                const str = _this.dataTabsType === 1 ? '收入' : _this.dataTabsType === 2 ? '消耗' : '毛利'
                console.log(_this.dataTabsType)
                a += '<p>总' + str + '：' + _this.conf.symbol + million(allNum, 2) + '<p>'
              }
              for (let index = 0; index < params.length; index++) {
                const element = params[index]
                a +=
                  '<p>' +
                  element.marker +
                  element.seriesName +
                  '：' +
                  _this.conf.symbol +
                  million(element.value, 2) +
                  '</p>'
              }
              a += '</div>'
              return a
            }
          }
          this.dataList = {
            legendArray,
            xAxisArray,
            yAxisArray,
            seriesArray,
            tooltipArray,
            gridArray,
            dataZoomArray
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    /**
     * 年月切换
     */
    fnClickTabs(option) {
      this.active = option.index
      this.time_type = option.item.value
      sessionStorage.setItem('currentTimeType', option.item.value)
      sessionStorage.setItem('currentIndex', option.index)
      this.fnInit()
    },
    /**
     * 表格左右滑动
     */
    fnScroll(e) {
      // 判断是否已经左滑
      this.boxLeft = e.target.scrollLeft
      if (this.boxLeft > 0) {
        this.isLeftShow = true // 显示左列表浮动
        this.topLeft = -e.target.scrollLeft // 设置左浮动列的x轴位置
      } else {
        this.isLeftShow = false // 隐藏左列浮动
        this.topLeft = 0
      }
    },
    /**
     * 监听滚动条滚动
     */
    handScroll() {
      // 在节点挂载之后，获取dom元素
      const toTop = this.$refs.dataScroll.getBoundingClientRect().top // 实时获取概览距离顶部位置，用于判断是否需要将表格头固定和切换时间栏固定
      const bottomListTop = this.$refs.bottomList.getBoundingClientRect().top // 实时获取表格列表的位置，用于计算左列浮动时的偏移量
      // 判断是否需要固顶
      if (toTop <= 0) {
        this.isHideShow = true // 显示固顶表头
        this.leftTopFix = this.topHeight // 设置浮动列距离顶部位置
      } else {
        this.isHideShow = false // 隐藏固顶表头
        // 先判断表格是否已经左滑，左滑就不在隐藏左列浮动
        if (this.topLeft === 0) {
          this.isLeftShow = false // 隐藏左列浮动
        }
        this.leftTopFix = bottomListTop - this.columnHeight // 动态设置浮动列距离顶部位置
      }
      // 判断滚动条是否已经到达数据表格的内容位置，需要动态设置滚动左列浮动的内容（数据表格top的位置<=顶部操作固定浮动的高度+表头的高度）
      if (bottomListTop <= this.topHeight + this.columnHeight) {
        this.topTop = bottomListTop - (this.topHeight + this.columnHeight)
      } else {
        this.topTop = 0
      }
      console.log(this.topTop)
    },
    /**
     * tabs切换
     */
    fnClickDataTabs(option) {
      console.log(option)
      this.dataTabsType = option.item.value
      this.fnDataTypeChart()
    },
    /**
     * 打开详情
     */
    fnOpenDetails(e) {
      console.log(e)
      const gameString = e.split('|')
      const game_id = parseInt(gameString[1])
      const game_name = gameString[0]
      if (game_id) {
        this.$router.push({ name: 'Details', query: { game_id, game_name } })
      }
    },
    /**
     * 点击全屏
     */
    fnFullScree() {
      console.log('全屏点击')
      this.showChartFull = !this.showChartFull
    },
    /**
     * 打开提示
     */
    fnClickTips() {
      this.isTipsShow = true
    }
  },
  filters: {
    gamename(value, n) {
      if (typeof value !== 'string') {
        return value
      }
      return value.split('|')[n]
    }
  }
}
</script>

<style lang="less" scoped>
.scroll-top {
  height: ((116+48) / @num);
}
.head {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: (116 / @num);
  background: #fff;
  padding-left: (20 / @num);
}
.time {
  display: flex;
  align-items: center;
  height: (48 / @num);
  color: @font-a3a3a3;
  font-size: @font-24;
  padding-left: (32 / @num);
  background: @font-F5F5F5;
}
.head-box {
  background: #fff;
  padding-bottom: (30 / @num);
  .head-top {
    display: flex;
    font-size: @font-24;
    padding: (22 / @num) (32 / @num) (10 / @num) (32 / @num);
    h2 {
      font-weight: normal;
    }
    .head-top-left {
      color: @font-2a2a2a;
      padding-right: (20 / @num);
      display: flex;
      align-items: flex-end;
      .s {
        padding-left: (15 / @num);
        line-height: (30 / @num);
        color: @font-7a7a7a;
        font-size: @font-28;
      }
      &.h {
        color: @font-ea5656;
      }
      &.r {
        color: @font-59a252;
      }
      h2 {
        font-size: @font-44;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-end;
        i {
          line-height: (38 / @num);
          font-style: normal;
        }
        span {
          font-size: (24 / @num);
          line-height: (28 / @num);
          padding-left: (8 / @num);
        }
      }
      p {
        padding-top: (10 / @num);
      }
    }
  }
}
.tabs {
  padding: 0 (40 / @num);
  margin-top: (12 / @num);
  margin-bottom: (12 / @num);
  height: (80 / @num);
  background: #fff;
  position: relative;
  .tabs-wh {
    position: absolute;
    width: (50 / @num);
    height: (50 / @num);
    top: (18 / @num);
    right: (70 / @num);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    span {
      display: block;
      width: (28 / @num);
      height: (28 / @num);
      background: url('../../assets/wh@2x.png') no-repeat;
      background-size: 100%;
    }
  }
}
.head-list {
  font-size: (28 / @num);
  padding: 0 (32 / @num);
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-top: (24 / @num);
      width: 50%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      span {
        color: @font-7a7a7a;
        display: block;
      }
      &.t {
        padding-right: (40 / @num);
      }
    }
  }
}
.pielist {
  height: (496 / @num);
  background: #fff;
}
.pielist-box {
  display: flex;
  .pielist-box-left {
    position: relative;
    margin-left: (24 / @num);
    width: (334 / @num);
    .pielist-box-left-total {
      position: absolute;
      top: (155 / @num);
      left: (85 / @num);
      width: (170 / @num);
      text-align: center;
      font-size: @font-24;
    }
  }
  .pielist-box-right {
    margin-left: (8 / @num);
    ul {
      li {
        display: flex;
        align-items: center;
        margin-bottom: (42 / @num);
        span {
          display: block;
          font-size: @font-24;
          margin-left: (8 / @num);
          &.s1 {
            width: (16 / @num);
            height: (16 / @num);
            border-radius: (16 / @num);
            &.c1 {
              background: @paramcolor;
            }
            &.c2 {
              background: #4fcb74;
            }
            &.c3 {
              background: #fbd438;
            }
            &.c4 {
              background: #f04764;
            }
            &.c5 {
              background: #9860e5;
            }
          }
          &.s2 {
            width: (124 / @num);
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
          &.s3 {
            width: (56 / @num);
          }
        }
      }
    }
  }
}
.pielist-title,
.data-list-title {
  padding-left: (32 / @num);
  padding-top: (32 / @num);
  padding-bottom: (34 / @num);
  font-size: (28 / @num);
  color: @font-686868;
}

.data-list {
  position: relative;
  height: (536 / @num);
  background: #fff;
  margin: (2 / @num) 0;
  .data-list-quanping {
    position: absolute;
    right: (38 / @num);
    top: (40 / @num);
    width: (28 / @num);
    height: (28 / @num);
    em {
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../assets/quanping3@2x.png') no-repeat;
      background-size: 100%;
    }
  }
}

.data-overview {
  position: relative;
  margin-top: (12 / @num);
  .data-overview-top {
    height: (96 / @num);
    background: #fff;
    padding: 0 (32 / @num);
    h2 {
      font-size: @font-32;
      display: flex;
      align-items: center;
      color: @font-2F2F2F;
      padding-top: (20 / @num);
      em {
        width: (8 / @num);
        height: (32 / @num);
        background: @paramcolor;
        border-radius: (4 / @num);
        margin-right: (16 / @num);
      }
    }
    .data-overview-top-tabs {
      margin-left: -(26 / @num);
      width: (360 / @num);
      height: (80 / @num);
    }
  }
  .data-overview-center {
    height: (64 / @num);
    background: #f5f5f5;
    color: @font-a3a3a3;
    .data-overview-center-list {
      color: @font-a3a3a3;
      display: flex;
      align-items: center;
      font-size: @font-24;
      height: 100%;
      padding-left: (32 / @num);
      padding-right: (32 / @num);
      span {
        display: block;
        margin-left: (20 / @num);
        color: @paramcolor;
      }
    }

    .data-overview-center-time {
      display: flex;
      align-items: center;
      padding-top: (8 / @num);
      margin-left: (32 / @num);
      .data-overview-center-time-box {
        display: flex;
        align-items: center;
        font-size: @font-24;
        width: (526 / @num);
        height: (48 / @num);
        background: #ffffff;
        border-radius: (24 / @num);
        border: (2 / @num) solid #c6c8cc;
        margin-right: (32 / @num);
        padding-left: (32 / @num);
        input {
          width: (140 / @num);
          margin: 0 (48 / @num);
          text-align: center;
          color: @font-a3a3a3;
          font-size: @font-24;
          border: none;
        }
      }
    }
  }
  .data-overview-bottom {
    position: relative;
    background: #fff;
    padding-bottom: (20 / @num);
    width: 100%;
    overflow: auto;
    table {
      th {
        width: (180 / @num);
        font-size: @font-28;
        color: @font-7a7a7a;
        font-weight: normal;
        height: (52 / @num);
        padding: (10 / @num);
      }
      td {
        padding: (10 / @num);
        font-size: @font-28;
        text-align: center;
      }
    }
  }
}

.data-overview-bottom-column,
.data-overview-left-top {
  display: flex;
  align-items: center;
  background: @font-E8E8E8;
  height: 30px;
  span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90px;
    font-size: (28 / @num);
    font-weight: normal;
    height: 100%;
    text-align: right;
    color: @font-2F2F2F;
    &.l {
      padding-left: (32 / @num);
      padding-right: (20 / @num);
      width: 100px;
      justify-content: flex-start;
      text-align: left;
    }
  }
}
.data-overview-bottom-column {
  &.g {
    padding-right: (20 / @num);
  }
}
.data-overview-bottom-column-span {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  padding-right: (20 / @num);
}
.data-overview-left-top {
  position: relative;
  z-index: 4;
}
.data-overview-left {
  background: #fff;
  &.boxshadow {
    box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.12);
  }
}
.data-overview-bottom-box-list {
  display: flex;
  align-items: center;
  font-size: 0;
  padding-right: (20 / @num);
  padding: (20 / @num) 0;
  border-bottom: (2 / @num) solid @font-F7F7F7;
  &.b {
    background: #eee;
  }
  span {
    display: block;
    width: 90px;
    font-size: (28 / @num);
    text-align: right;
    &.l {
      padding-left: (32 / @num);
      padding-right: (20 / @num);
      width: 100px;
      text-align: left;
    }
  }
}
.chart-full-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background: #fff;
  .chart-full-box-list {
    height: (750 / @num);
    transform: rotate(90deg);
  }
  .chart-full-box-close {
    position: absolute;
    right: (20 / @num);
    bottom: (20 / @num);
    width: (52 / @num);
    height: (52 / @num);
    span {
      display: block;
      width: 100%;
      height: 100%;
      background: url('../../assets/close@2x.png') no-repeat;
      background-size: 100%;
    }
  }
}
.tips {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: (526 / @num);
  height: (432 / @num);
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(177, 180, 183, 0.5);
  border-radius: (16 / @num);
  p {
    font-size: @font-24;
    padding: (48 / @num) (32 / @num);
    line-height: (40 / @num);
  }
  .tips-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: (40 / @num);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: (462 / @num);
    height: (64 / @num);
    color: @font-FFFFFF;
    background: @paramcolor;
    font-size: @font-28;
    border-radius: (32 / @num);
  }
}
</style>