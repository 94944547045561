import request from '@/utils/request'

const api = {
  dataTypeOverview: '/api/data/typeoverview', // 游戏大类概览
  dataTypeChart: '/api/data/typechart', // 游戏大类图表
  dataTypeDetail: '/api/data/typedetail'// 游戏大类详情
}

export function dataTypeOverview(parameter) {
  return request({
    url: api.dataTypeOverview,
    method: 'get',
    params: parameter
  })
}

export function dataTypeChart(parameter) {
  return request({
    url: api.dataTypeChart,
    method: 'get',
    params: parameter
  })
}

export function dataTypeDetail(parameter) {
  return request({
    url: api.dataTypeDetail,
    method: 'get',
    params: parameter
  })
}