import request from '@/utils/request'

const api = {
  dataGamedetailOverview: '/api/data/gamedetailoverview', // 数据详情
  dataGamedetail: '/api/data/gamedetail', // 数据概览
  dataGamedetailChart: '/api/data/gamedetailchart'//图表详情
}

export function dataGamedetailOverview(parameter) {
  return request({
    url: api.dataGamedetailOverview,
    method: 'get',
    params: parameter
  })
}

export function dataGamedetail(parameter) {
  return request({
    url: api.dataGamedetail,
    method: 'get',
    params: parameter
  })
}

export function dataGamedetailChart(parameter) {
  return request({
    url: api.dataGamedetailChart,
    method: 'get',
    params: parameter
  })
}